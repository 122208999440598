exports.components = {
  "component---src-templates-accommodation-js": () => import("./../../../src/templates/accommodation.js" /* webpackChunkName: "component---src-templates-accommodation-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-drinkslist-js": () => import("./../../../src/templates/drinkslist.js" /* webpackChunkName: "component---src-templates-drinkslist-js" */),
  "component---src-templates-food-js": () => import("./../../../src/templates/food.js" /* webpackChunkName: "component---src-templates-food-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

